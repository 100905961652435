export default defineNuxtRouteMiddleware(async (to, from) => {
  const authStore = useAuthStore();
  const checkoutStore = useCheckoutStore();
  const localePath = useLocalePath();

  //---- 1. Buy now middleware logic
  if (!authStore.is_authenticated){
    const rootStore = useRootStore();
    rootStore.after_auth_intended_route = to.fullPath;
    return await navigateTo(localePath("/auth/signin"));
  }

  // 2. If buy now trolley has been deleted
  //---- NOTE: refreshing a protected page causes "to" and "from" path to be the same as current route
  if (!checkoutStore.buy_now_trolley.id) {
    if (to.fullPath.includes("buy-now") && !from.fullPath.includes("buy-now")) {
      const rootStore = useRootStore();
      // store the source route in state for analytics
      if (!from.fullPath.includes("auth"))
        rootStore.buy_now_source_route = from.fullPath;
      return await navigateTo(localePath(from.fullPath || '/'));
    }
    // handle reload or direct visit condition @todo: debug -> 'back' route is unavailable on the server
    else
    {
      const router = useRouter();
      return await navigateTo(localePath((router.options.history.state.back || '/') as string));
    }
  }
});
